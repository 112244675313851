@import "../../index";

.explorer {
  padding: 10px;
  margin: auto;

  .playground {
    height: 80vh;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  .graphiql-logo {
    display: none;
  }

  .toolbar-button{
    display: inline;
  }

  .enter-clientId-label {
    width: 100%;
    margin: auto;
    display: inline-block;
    position: relative;
  }

  .error-message {
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .align-center {
    text-align: center;
  }

  .client-id-input {
    height: 40px;
    font-size: 14px;
    font-style: italic;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    width: 90%;
  }

  .clientId-modal-body {
    padding: 1rem 1rem 1rem 0
  }

  .clientId-save-button {
    //width: 300px;
  }

  .logged-out {
    opacity: 0.6;
    pointer-events: none;
  }

  .sign-in-out-button {
    float: right;
    margin-bottom: 5px;
  }

  .client-id-label {
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin-top: 10px;
    font-style: italic;
  }

  .authenticating-spinner {
    height: 100%;

    .spinner {
      height: 100%;
    }

    .spinner-gears {
      margin: auto;
    }
  }

  //Hide tracing on the right panel
  .sc-hZSUBg {
    display: none;
  }

  //Hide tracing comment on the right panel
  .sc-caSCKo {
    display: none;
  }


}

.modal-popup-container {
  height: auto;
  .modal_body{
    overflow: clip;
  }
}