@import '~@carfax-stencils/styles/src/index.scss';
@import '~@carfax-stencils/modal/src/index.scss';
@import '~@carfax-stencils/button/src/index.scss';
@import '~@carfax-stencils/form-element/src/index.scss';
@import '~@carfax-stencils/input-text/src/index.scss';
@import '~@carfax-stencils/spinner/src/index.scss';
@import "~animate.css/animate.min.css";
@import '~@carfax-stencils/notification/src/index.scss';

$desktop-width: 1280px;
$tablet-width: 992px;
$tablet-width-portrait: 768px;
$phone-width: 576px;

body {
  height: 100%;
  margin: 0;
  font-family: Roboto,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-body-medium;

}

ul, ol {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

ol {
  li {
    list-style-type: decimal;
  }
  ol {
    margin-block-start: 0px;
    margin-block-end: 0px;
    
    li {
      list-style-type: lower-latin;
    }
  }
}

ul {
   ul {
     list-style-type: circle;
     margin-block-start: 0px;
     margin-block-end: 0px;
  
     li {
       list-style-type: circle;
     }
   }
  
   li {
     display: list-item;
     text-align: -webkit-match-parent;
     list-style-type: disc;
   }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.col-md-9 img {
  max-width: 100%;
  display: inline-block;
  padding: 4px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  margin: 20px auto 30px auto;
}