@import "src/index";

.collapsible-content-container {
  .expand-collapse-button {
    margin: -30px auto 0 auto;
  }

  .collapsible-content {
    max-height: 20vh;
    transition: max-height 0.35s ease-in-out;
    -o-transition: max-height 0.35s ease-in-out;
    -moz-transition: max-height 0.35s ease-in-out;
    -webkit-transition: max-height 0.35s ease-in-out;
    overflow: hidden;
    position: relative;

    &.expanded {
      max-height: 400vh;

      .overlay-container {
        background: none;
      }
    }

    .overlay-container {
      position: absolute;
      bottom: 0;
      height: 50%;
      width: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(30, 30, 30, 0.9) 40%);
      pointer-events: none;
    }
  }
}
