.dealer {
  .col-md-9 img {
    margin-bottom: 20px;
  }
  .question {
    font-weight: bold;
    font-size: 120%;
    margin-bottom: 8px;
    margin-top: 15px;
  }
  .chbv-table{
    width:50%;
    img{
      margin: 5px auto 5px 5px;
    }
  }
}