@import "../../index";

.footer-container {
  max-width: 100%;
  background: #43474f;
  color: #eee;
  padding: 0 0 30px 0;
  font-size: small;

  .row {
    width: 100%;
    margin-left: 0;
  }

  .footer-logo {
    margin-top: 25px;
    margin-bottom: 0;
    height: 35px;
  }

  .col-md-4{
    padding-right: 0;
  }

  .footer-h-line {
    background-color: gray;
    width: 100%;

  }

  .footer-block{
    width: 80%;
    margin: auto;
  }

  a{
    color: #ffffff;
  }

  .footer-col-bar{
    border-right: 1px solid white;
  }

  .footer-copyright {
    div {
      padding: 0;

      &[class*="col-md"] {
        margin: 0;
      }
    }
  }

  @media only screen and (min-width: $tablet-width-portrait) {
    .footer-copyright {
      div {
        &[class*="col-md"] {
          text-align: center;
          border-right: 1px solid white;
        }
        &[class*="col-md"]:last-child {
          border-right: 0;
        }
      }
    }
  }
}
