@import "src/index";

.content-template-container {
  padding-top: 15px;
  padding-bottom: 15px;
  max-width: 90%;
  margin: auto;

  .content-container {
    min-height: 80vh;

    img{
      padding: 0;
    }
    section {
      margin: 15px 0;

      p {
        margin: 15px 0 30px 0;

        + ul {
          margin-top: -10px;
        }
      }
    }

    hr {
      margin: 40px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    h1 {
      margin-bottom: 20px;
      text-align: center;
      @extend %font-weight-light !optional;
    }

    h3, h3 {
      padding-top: 90px;
      margin-top: -90px;
    }

    h5, h5 {
      padding-top: 90px;
      margin-top: -90px;
    }

    pre {
      background: #f8f8f8;
      max-width: 100%;
      border: solid 1px #bdbdbd;
      color: #333;
      overflow-x: auto;
      display: block;
      padding: 9.5px;
      margin: 0 0 10px;
      font-size: 13px;
      line-height: 1.428571429;
      word-break: break-all;
      border-radius: 4px;
    }

    code {
      padding: 2px 5px;
      background: #fff;
      border: solid 1px #e1e4e5;
      color: #333;
      white-space: pre-wrap;
    }
  }

  .graphql, .json, .curl {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    color: #333;
    background: #f8f8f8;
    font-family: monospace, serif;
    border: none;
    white-space: pre;
  }

  .padding-for-sidenav {
    padding: 0.5rem;
  }

  .sidebar {
    padding: 10px;
    max-height: 90vh;
    overflow: auto;
    scrollbar-width: thin; // Firefox
    scrollbar-color: rgba(55, 119, 188, 0.0) transparent;

    &:hover {
      scrollbar-color: rgba(55, 119, 188, 0.5) transparent;
    }

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(55, 119, 188, 0.0);
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(55, 119, 188, 0.5);
    }

    .list-group {
      margin-top: 0;
      margin-bottom: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      .list-group-item {
        padding: 0;

        .nav-link {
          color: $grey-dark;
          border-left: 2px solid white;
          padding: 0.5rem 0.5rem;

          &:hover {
            color: $blue-dark;
            border-left: 2px solid $blue-dark;
          }
        }
      }

      .list-group-item.active {
        background-color: #fff;
        border-color: $grey-light;

        a {
          border-left: 2px solid $blue-dark;
          color: $blue-dark;
        }
      }
    }
  }

  @media only screen and (min-width: $tablet-width-portrait) {
    .sidebar {
      position: -webkit-sticky;
      position: sticky;
      top: 95px;
    }
  }
}
