.faq {
  .question {
    font-weight: bold;
    font-size: 120%;
    margin-bottom: 8px;
    margin-top: 15px;
  }

  .add-padding {
    padding-bottom: 5px;
  }
}