@import "../../index";

.cd-navbar {
  max-width: 100%;
  margin: 0;
  padding: 0;
  background-color: #3777bc;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  font-size: $font-body-large;
  @extend %font-weight-medium !optional;

  .nav-container {
    width: 80%;
    margin: auto;

    .navbar-logo {
      width: 180px;
    }

    .nav-item-container {
      .nav-item {
        &.cd-dropdown {
          cursor: pointer;

          .cd-dropdown-menu {
            background-color: $blue;
            padding: 8px 0 8px 0;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            transform: scale(0);
            transform-origin: top;
            max-height: 0;
            transition: max-height 0.1s ease-in;

            &.show {
              animation: scaleDown 0.1s linear both;
              -webkit-animation: scaleDown 0.1s linear both;
              max-height: 200px;

              .dropdown-item {
                animation: fadeInTop 0.3s linear both;
                animation-delay: 0.1s;
                -webkit-animation: fadeInTop 0.3s linear both;
                -webkit-animation-delay: 0.1s;
              }
            }

            &.hide {
              animation: scaleUp 0.1s linear both;
              -webkit-animation: scaleUp 0.1s linear both;
            }

            .dropdown-item {
              color: white;

              &:hover {
                background-color: $blue-dark;
              }

              &:focus {
                background-color: $blue-dark;
              }
            }
          }
        }

        &.dropdown {
          .dropdown-menu {
            background-color: $blue;
            border: none;
          }

          .dropdown-item {
            color: white;
          }
        }
        .nav-link {
          color: white;
        }
      }
    }

    .navbar-toggler {
      border: none;
      position: relative;

      &:focus {
        outline: none;
      }

      &:not(.collapsed) {
        span {
          &:first-child {
            transform: translateY(8px) rotate(45deg);
          }

          &:nth-child(2) {
            width: 0;
          }

          &:nth-child(3) {
            transform: translateY(-8px) rotate(-45deg);
          }
        }
      }

      span {
        height: 2px;
        width: 30px;
        background-color: #fff;
        display: block;
        position: absolute;
        right: 0;
        transform-origin: center;
        transition: all 0.2s;

        &:first-child {
          top: 0;
        }

        &:nth-child(2) {
          top: 8px;
        }

        &:nth-child(3) {
          top: 16px;
        }
      }
    }

    .vl {
      border-left: 1px solid white;
      height: 65%;
      margin: auto;
    }
  }

  @media only screen and (max-width: $tablet-width-portrait) {
    .nav-container {
      width: 100%;

      .nav-item-container {
        margin: 10px;
        border-bottom: 1px solid white;

        .nav-item {
          padding: 8px 0 16px 0;

          &.cd-dropdown {
            padding-bottom: 0;
            display: flex;
            flex-direction: column;

            .nav-link {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: $tablet-width) {
    .nav-container {
      padding: 0;
      height: 80px;

      .nav-item-container {
        display: table;
        height: 100%;

        &:hover {
          background-color: $blue-dark;
        }
      }

      .nav-item {
        position: relative;
        display: table-cell;
        vertical-align: middle;
      }

      .cd-dropdown-menu {
        position: absolute;
        top: 80px;
        z-index: 2;
        left: 0;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
      }
    }

    .navbar-nav {
      height: 100%;
    }

    .navbar-collapse {
      height: 100%;
    }
  }

  @keyframes scaleDown {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }

  @keyframes scaleUp {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }

  @-webkit-keyframes scaleDown {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }

  @-webkit-keyframes scaleUp {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }
}
