@import "src/index";

.cd-app {
  .container {
    max-width: 90%;
    min-height: 500px;
  }

  .box-shadow {
    @include boxShadow();
  }
}

/* IE11 stuff */
.ie-message-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bars-header {
  height: 64px;
  width: 100%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 64px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
}
#ie-carfax-logo {
  width: 150px;
}
#ie-carfax-bubble {
  margin-top: 135px;
  margin-bottom: 16px;
  height: 274px;
  width: 274px;
}
.ie11-message-header-text {
  color: #212121;
  font-family: Roboto, serif;
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  text-align: center;
  margin: 32px;
}
.ie11-message-paragraph-text {
  color: #212121;
  font-family: Roboto, serif;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin: 16px;
}
.ie11-message-anchor {
  color: #1976d2;
}